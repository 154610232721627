import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { styled } from '../../themes/common';

export interface ErrorDialogSkeletonProps extends Omit<DialogProps, 'title'> {
  header?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  actions?: ReactNode;
}

const Container = styled('div')({
  width: 600,
  minHeight: 630,
  display: 'flex',
  flexDirection: 'column',
});

const Header = styled('div')(({ theme }) => ({
  height: 440,
  color: theme.palette.primary.contrastText,
  background: theme.palette.primary.main,
  fontFamily: theme.typography.monospace.fontFamily,
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '10rem',
  flex: '0 0 auto',
}));

const Content = styled(DialogContent)({
  flex: '1 1 auto',
});

const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  fontSize: '1.1em',
  marginTop: theme.spacing(1),
}));

/** A component that allows to create an error dialog */
const ErrorDialogSkeleton: FunctionComponent<ErrorDialogSkeletonProps> = ({
  header,
  title,
  description,
  actions,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Dialog {...props}>
      <Container>
        <Header>
          {header || <FontAwesomeIcon icon={faExclamationTriangle} />}
        </Header>
        <Content>
          <Typography variant="h5" fontFamily="Apex New">
            {title || t('generic_error_title')}
          </Typography>
          {description && <Description>{description}</Description>}
          {children}
        </Content>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Container>
    </Dialog>
  );
};

export default ErrorDialogSkeleton;
