import i18n, { BackendModule, ResourceKey } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Settings } from '../providers/SettingsProvider';
import {
  PseudoLocalizationStrategy,
  pseudoLocalizeResource,
} from '../helpers/pseudoLocalization';
import { appEnvironment } from './constants';

export const pseudoLocalizationEnabled =
  appEnvironment !== 'production' && appEnvironment !== 'beta';

export type Language = 'pseudo-accented' | 'pseudo-rtl' | 'en' | 'it';
export const supportedLanguages: Language[] = [
  ...(pseudoLocalizationEnabled
    ? (['pseudo-accented', 'pseudo-rtl'] as Language[])
    : []),
  'en',
  'it',
];
export interface ResourceModule {
  default: ResourceKey;
}

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'settings',
  lookup: () => {
    const storedSettings: Partial<Settings> = JSON.parse(
      localStorage.getItem('settings') || '{}',
    );
    const userLanguage =
      storedSettings.language || navigator.language.slice(0, 2);
    return supportedLanguages.includes(userLanguage as Language)
      ? userLanguage
      : supportedLanguages[0];
  },
});

void i18n
  .use({
    type: 'backend',
    read(language, namespace, callback) {
      const loadLanguage = async () => {
        if (pseudoLocalizationEnabled && language.startsWith('pseudo')) {
          const { default: enResource }: ResourceModule = await import(
            `../locales/en/${namespace}.json`
          );
          // Strip the 'pseudo-' prefix to get the actual strategy name
          const pseudoStrategy = language
            .slice(7)
            .toLowerCase() as PseudoLocalizationStrategy;
          return pseudoLocalizeResource(enResource, pseudoStrategy);
        }

        const { default: resource }: ResourceModule = await import(
          `../locales/${language}/${namespace}.json`
        );
        return resource;
      };

      loadLanguage()
        .then(resource => callback(null, resource))
        .catch((error: Error) => callback(error, null));
    },
  } as BackendModule)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    detection: {
      order: ['settings'],
    },
    returnNull: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

// Update HTML lang attribute on language change (for accessibility)
i18n.on('languageChanged', (lang: Language) => {
  document.documentElement.lang = lang.startsWith('pseudo') ? 'en' : lang;
});

export default i18n;
