import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs, HandlerType } from '@datadog/browser-logs';
import GenericErrorDialog from '../components/errors/GenericErrorDialog';
import {
  appEnvironment,
  appPlatform,
  appShortName,
  appVersion,
} from '../config/constants';
import { useAuth } from './AuthProvider';
import { ErrorBoundary } from '../components/errors/ErrorBoundary';

const datadogConfig = {
  applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  site: import.meta.env.VITE_DATADOG_SITE,
  service: appShortName,
  env: appEnvironment,
  version: appVersion,
  trackInteractions: true,
  trackFrustrations: true,
  forwardErrorsToLogs: true,
};

// Setup RUM
datadogRum.init(datadogConfig);
datadogRum.startSessionReplayRecording();
// Setup logs
datadogLogs.init(datadogConfig);
// Disable logging to Datadog in dev mode
datadogLogs.logger.setHandler(
  appEnvironment === 'dev' ? HandlerType.console : HandlerType.http,
);

const MonitoringProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { user } = useAuth();
  const { i18n } = useTranslation();

  useEffect(() => {
    const datadogContext = {
      ...(user?.id && { userId: user?.id.toString() }),
      environment: appEnvironment,
      appName: appShortName,
      appPlatform,
      appVersion,
      userAgent: navigator.userAgent,
      language: i18n.language,
    };

    if (user?.id) {
      datadogRum.setUser({ id: user?.id.toString() });
    }
    datadogRum.setRumGlobalContext(datadogContext);
    datadogLogs.logger.setContext(datadogContext);
  }, [i18n.language, user?.id]);

  return (
    <ErrorBoundary
      fallback={({ error, errorInfo: { componentStack } = {}, resetError }) => (
        <GenericErrorDialog
          open
          error={error}
          componentStack={componentStack ?? undefined}
          onTryAgainClick={resetError}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default MonitoringProvider;
