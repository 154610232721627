import React, { FunctionComponent } from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';
import { styled } from '../../themes/common';

export interface CenteredSpinnerProps
  extends Omit<CircularProgressProps, 'size'> {
  size?: number;
}

const Container = styled('div')<{
  spinnerSize?: CenteredSpinnerProps['size'];
}>(({ spinnerSize = 24 }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: `-${spinnerSize / 2}px`,
  marginLeft: `-${spinnerSize / 2}px`,
}));

const CenteredSpinner: FunctionComponent<CenteredSpinnerProps> = ({
  size,
  ...props
}) => (
  <Container spinnerSize={size}>
    <CircularProgress size={size} {...props} />
  </Container>
);

export default CenteredSpinner;
