import React, {
  FunctionComponent,
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
  PropsWithChildren,
} from 'react';
import { Language } from '../config/i18n';
import { TemperatureUnit } from '../helpers/utils';

export type DateTimeFormat = 'language' | 'us' | 'iso';

export interface Settings {
  language: Language;
  temperatureUnit: TemperatureUnit;
  dateFormat: DateTimeFormat;
  timeFormat: DateTimeFormat;
}

export interface SettingsContextValue {
  /**
   * The current settings.
   */
  settings: Settings;
  /**
   * Change a single setting value.
   * @example changeSetting('language', 'en');
   */
  changeSetting<K extends keyof Settings>(
    this: void,
    setting: K,
    value: SetStateAction<Settings[K]>,
  ): void;
  /**
   * Change multiple settings values at once.
   * @example changeSettings({ language: 'en', temperatureUnit: 'F' });
   */
  changeSettings(
    this: void,
    settings:
      | Partial<Settings>
      | ((prevSettings: Settings) => Partial<Settings>),
  ): void;
}

const getSavedSettings = (): Settings => {
  const storedSettings = JSON.parse(localStorage.getItem('settings') || '{}');

  // Merge default settings with the stored ones
  return {
    language: 'en',
    temperatureUnit: 'F',
    dateFormat: 'language',
    timeFormat: 'language',
    ...(storedSettings as Partial<Settings>),
  };
};

const savedSettings = getSavedSettings();

export const SettingsContext = createContext<SettingsContextValue>({
  settings: savedSettings,
  changeSetting: () => {
    /* no-op */
  },
  changeSettings: () => {
    /* no-op */
  },
});

export const SettingsProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [settings, setSettings] = useState(savedSettings);

  useEffect(() => {
    localStorage.setItem('settings', JSON.stringify(settings));
  }, [settings]);

  const changeSetting = useCallback<SettingsContextValue['changeSetting']>(
    (setting, value) =>
      setSettings(prevSettings => ({
        ...prevSettings,
        [setting]:
          typeof value === 'function' ? value(prevSettings[setting]) : value,
      })),
    [],
  );

  const changeSettings = useCallback<SettingsContextValue['changeSettings']>(
    newSettings =>
      setSettings(prevSettings => ({
        ...prevSettings,
        ...(typeof newSettings === 'function'
          ? newSettings(prevSettings)
          : newSettings),
      })),
    [],
  );

  return (
    <SettingsContext.Provider
      value={{ settings, changeSetting, changeSettings }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = (): SettingsContextValue =>
  useContext(SettingsContext);

export default SettingsProvider;
