import React, { FunctionComponent } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckboxUncheckedIcon: FunctionComponent<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 19 20" {...props}>
    <path d="M15,19.6H4a4,4,0,0,1-4-4V4.63a4,4,0,0,1,4-4H15a4,4,0,0,1,4,4V15.57A4,4,0,0,1,15,19.6ZM4,1.6a3,3,0,0,0-3,3V15.57a3,3,0,0,0,3,3H15a3,3,0,0,0,3-3V4.63a3,3,0,0,0-3-3Z" />
  </SvgIcon>
);

export default CheckboxUncheckedIcon;
