import React, { FunctionComponent } from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

const CheckboxIndeterminateIcon: FunctionComponent<SvgIconProps> = props => {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 19 20" {...props}>
      <path d="M0 4.6a4 4 0 014-4h11a4 4 0 014 4v11a4 4 0 01-4 4H4a4 4 0 01-4-4v-11z" />
      <path stroke={theme.palette.primary.contrastText} d="M4 10.1h11.1" />
    </SvgIcon>
  );
};

export default CheckboxIndeterminateIcon;
