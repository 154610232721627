import {
  ReactNode,
  Component,
  isValidElement,
  PropsWithChildren,
  ErrorInfo,
} from 'react';
import { datadogRum } from '@datadog/browser-rum';

export interface FallbackRendererData {
  error: Error;
  errorInfo: ErrorInfo;
  resetError(): void;
}

type FallbackRenderer = (data: FallbackRendererData) => React.ReactNode;

interface ErrorBoundaryState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
  prevScope: string | null;
}

export interface ErrorBoundaryProps {
  fallback?: ReactNode | FallbackRenderer;
  scope?: string;
  beforeCapture?(error: Error, errorInfo: ErrorInfo): Record<string, unknown>;
}

const INITIAL_STATE: Readonly<ErrorBoundaryState> = {
  error: null,
  errorInfo: null,
  prevScope: null,
};

/**
 * ErrorBoundary component sends enriched errors to RUM.
 */
export class ErrorBoundary extends Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  static defaultProps: Readonly<PropsWithChildren<ErrorBoundaryProps>> = {
    scope: 'error-boundary',
  };

  state: Readonly<ErrorBoundaryState> = {
    ...INITIAL_STATE,
    prevScope: this.props.scope ?? null,
  };

  public resetErrorBoundary: () => void = () => {
    this.setState({
      ...INITIAL_STATE,
      prevScope: this.props.scope ?? null,
    });
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  static getDerivedStateFromProps(
    props: PropsWithChildren<ErrorBoundaryProps>,
    state: ErrorBoundaryState,
  ) {
    if (state.prevScope !== props.scope) {
      return {
        error: undefined,
        prevScope: props.scope,
      };
    }

    return state;
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { beforeCapture } = this.props;
    const context = beforeCapture?.(error, errorInfo) || {};

    datadogRum.addError(error, {
      ...errorInfo,
      ...context,
      scope: this.props.scope,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { fallback } = this.props;

    if (!error || !errorInfo) {
      return this.props.children;
    }

    if (isValidElement(fallback) || typeof fallback === 'string') {
      return fallback;
    }
    if (typeof fallback === 'function') {
      return fallback({
        error,
        errorInfo,
        resetError: this.resetErrorBoundary,
      });
    }
    return null;
  }
}
