import React, {
  FunctionComponent,
  createContext,
  useEffect,
  useCallback,
  useContext,
  useState,
  PropsWithChildren,
} from 'react';

export interface MenuContextValue {
  isOpen: boolean;
  toggle(this: void, open?: boolean): void;
}

const defaultMenuOpen = localStorage.getItem('menuOpen') === 'true';

export const MenuContext = createContext<MenuContextValue>({
  isOpen: defaultMenuOpen,
  toggle: () => undefined,
});

export const MenuProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(defaultMenuOpen);

  const toggle = useCallback<MenuContextValue['toggle']>(newIsOpen => {
    setIsOpen(previousIsOpen =>
      typeof newIsOpen === 'boolean' ? newIsOpen : !previousIsOpen,
    );
  }, []);

  useEffect(() => {
    localStorage.setItem('menuOpen', isOpen.toString());
  }, [isOpen]);

  return (
    <MenuContext.Provider value={{ isOpen, toggle }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = (): MenuContextValue => useContext(MenuContext);

export default MenuProvider;
