import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import ErrorDialogSkeleton, {
  ErrorDialogSkeletonProps,
} from './ErrorDialogSkeleton';
import LinkButton from '../common/LinkButton';
import { appEnvironment } from '../../config/constants';

export interface GenericErrorDialogProps extends ErrorDialogSkeletonProps {
  onTryAgainClick?(): void;
  error?: Error;
  componentStack?: string;
}

const GenericErrorDialog: FunctionComponent<GenericErrorDialogProps> = ({
  error,
  componentStack,
  onTryAgainClick = () => window.location.reload(),
  children,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <ErrorDialogSkeleton
      description={t('generic_error_description')}
      actions={
        <>
          <LinkButton color="inherit" to="/">
            {t('go_back')}
          </LinkButton>
          <Button variant="contained" onClick={onTryAgainClick}>
            {t('try_again')}
          </Button>
        </>
      }
      {...props}
    >
      {/* When not in production, also show the error */}
      {appEnvironment !== 'production' && error && (
        <pre
          style={{
            overflow: 'auto',
            background: '#fff0f0',
            color: '#ff000c',
            padding: 8,
            borderRadius: 5.5,
          }}
        >
          {componentStack
            ? `${error.toString()}: ${componentStack}`
            : error.stack || error.toString()}
        </pre>
      )}
      {children}
    </ErrorDialogSkeleton>
  );
};

export default GenericErrorDialog;
