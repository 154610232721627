import React, { FunctionComponent } from 'react';
import { Link, useResolvedPath } from 'react-router-dom';
import { createPath, To } from 'history';
import { Button, ButtonProps } from '@mui/material';

export interface LinkButtonProps extends ButtonProps {
  to: To;
  replace?: boolean;
}

/**
 * A component that wraps Material UI button and makes
 * it work properly with React Router's Link
 */
const LinkButton: FunctionComponent<LinkButtonProps> = ({
  to,
  replace,
  ...props
}) => {
  const path = useResolvedPath(to);
  if (typeof to === 'string' && to.startsWith('/')) {
    path.pathname = to.split(/[?#]/)[0];
  }
  const href = createPath(path);

  const buttonProps = {
    LinkComponent: Link,
    href,
    to: href,
    replace,
    ...props,
    // NOTE: we need to do a forced cast here because ButtonProps type is a bit buggy
  } as ButtonProps;

  return <Button {...buttonProps} />;
};

export default LinkButton;
