import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import App from './components/App';
import './config/i18n';

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY);

const root = createRoot(document.getElementById('root')!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
