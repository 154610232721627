import { appName, appPlatform, appVersion } from '../config/constants';
import { KeyVal } from '../hooks/api/models';
import { APIRequestOptions } from '../providers/APIProvider';

export const mergeUrlParts = (
  baseUrl: string,
  path?: string,
  query: KeyVal<string | number | (string | number)[]> = {},
): string => {
  const prettifiedBaseUrl = baseUrl.endsWith('/')
    ? baseUrl.slice(0, -1)
    : baseUrl;
  const prettifiedPath = path?.startsWith('/') ? path.slice(1) : path;
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(query)) {
    if (Array.isArray(value)) {
      for (const item of value) {
        searchParams.append(key, item.toString());
      }
    } else {
      searchParams.append(key, value.toString());
    }
  }
  const prettifiedSearchParams = searchParams.toString();
  const formattedPrettifiedPath = prettifiedPath ? `/${prettifiedPath}` : '';

  return `${prettifiedBaseUrl}${formattedPrettifiedPath}${
    prettifiedSearchParams ? `?${prettifiedSearchParams}` : ''
  }`;
};

export const request = (
  url: string,
  { body, ...options }: APIRequestOptions = {},
  token?: string | false,
): Promise<Response> =>
  fetch(url, {
    ...options,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'x-emp-app-name': appName,
      'x-emp-app-version': appVersion,
      'x-emp-app-platform': appPlatform,
      'content-type': 'application/json',
      accept: 'application/json',
      ...(token && { authorization: `Bearer ${token}` }),
      ...options.headers,
    },
  });
