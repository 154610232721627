import React from 'react';
import {
  PaletteMode,
  createTheme as createMuiTheme,
  styled as muiStyled,
  Theme,
  ThemeOptions,
} from '@mui/material';
import { CreateMUIStyled } from '@mui/system';
// eslint-disable-next-line no-restricted-imports
import {
  Typography,
  TypographyOptions,
  TypographyStyleOptions,
} from '@mui/material/styles/createTypography';
import { enUS, Localization } from '@mui/material/locale';
import isPropValid from '@emotion/is-prop-valid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import CheckboxUncheckedIcon from '../components/icons/CheckboxUncheckedIcon';
import CheckboxCheckedIcon from '../components/icons/CheckboxCheckedIcon';
import CheckboxIndeterminateIcon from '../components/icons/CheckboxIndeterminateIcon';

export interface DashboardThemeTypographyOptions extends TypographyOptions {
  display?: TypographyStyleOptions;
  text?: TypographyStyleOptions;
  monospace?: TypographyStyleOptions;
}

export interface DashboardThemeOptions
  extends Omit<ThemeOptions, 'typography'> {
  typography?: DashboardThemeTypographyOptions;
}

export interface DashboardThemeTypography extends Typography {
  display: TypographyStyleOptions;
  text: TypographyStyleOptions;
  monospace: TypographyStyleOptions;
}

export interface DashboardTheme extends Omit<Theme, 'typography'> {
  typography: DashboardThemeTypography;
}

// We re-export the function from Material UI so that we don't
// need to provide `DashboardTheme` to it everytime we use it
export const styled: CreateMUIStyled<DashboardTheme> = (
  component: Parameters<CreateMUIStyled<DashboardTheme>>[0],
  options?: Parameters<CreateMUIStyled<DashboardTheme>>[1],
) =>
  muiStyled(component, {
    // If component is native HTML tag (i.e. it is provided as a string)
    // we should provide to the underlying tag only valid native HTML attributes, otherwise React will complain
    ...(typeof component === 'string' && { shouldForwardProp: isPropValid }),
    ...options,
  }) as unknown as ReturnType<CreateMUIStyled<DashboardTheme>>;

export const displayFont = '"Apex New", sans-serif';
export const textFont = '"Lato", sans-serif';
export const monospaceFont = '"Inconsolata", monospace';

export const createTheme = (
  mode: PaletteMode,
  localization: Localization = enUS,
): DashboardTheme =>
  createMuiTheme(
    {
      spacing: 6,
      palette: {
        mode,
        primary: {
          main: mode === 'light' ? '#00778b' : '#6cd5ea',
        },
        secondary: {
          main: mode === 'light' ? '#f5f8f9' : '#3e3d3c',
        },
        background: {
          default: mode === 'light' ? '#fbfbfb' : '#161616',
        },
        success: {
          main: '#31bf68',
          contrastText: '#fff',
        },
        error: {
          main: '#E96374',
          contrastText: '#fff',
        },
      },
      typography: {
        fontSize: 14,
        htmlFontSize: 14,
        allVariants: {
          fontFamily: textFont,
        },
        display: {
          fontFamily: displayFont,
        },
        text: {
          fontFamily: textFont,
        },
        monospace: {
          fontFamily: monospaceFont,
        },
        body2: {
          fontWeight: 300,
          fontSize: '1rem',
        },
        h1: {
          fontFamily: displayFont,
          fontWeight: 400,
          fontSize: 27,
          lineHeight: '31px',
          color: '#00778b',
        },
        h2: {
          fontFamily: displayFont,
          fontWeight: 400,
          fontSize: 23,
          lineHeight: '26px',
          color: '#00778b',
        },
        h3: {
          fontFamily: displayFont,
          fontSize: 17,
          lineHeight: '21px',
        },
        h4: {
          fontFamily: displayFont,
          fontSize: 16,
          lineHeight: '20px',
        },
      },
      shape: {
        borderRadius: 5.5,
      },
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: '#2e384d',
            },
            arrow: {
              color: '#2e384d',
            },
          },
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              '& > *': {
                // !important is needed because otherwise some specific button types
                // would have a margin that takes precedence over this rule
                marginLeft: '8px !important',
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontWeight: 400,
            },
          },
        },
        MuiBreadcrumbs: {
          styleOverrides: {
            root: {
              fontFamily: displayFont,
              fontSize: '1rem',
              fontWeight: 300,
            },
          },
        },
        MuiSkeleton: {
          styleOverrides: {
            rectangular: {
              borderRadius: 4,
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            selectLabel: {
              fontFamily: displayFont,
            },
            select: {
              fontFamily: monospaceFont,
              fontSize: '1rem',
            },
            displayedRows: {
              fontFamily: monospaceFont,
              fontWeight: 400,
            },
          },
        },
        MuiCheckbox: {
          defaultProps: {
            icon: <CheckboxUncheckedIcon />,
            checkedIcon: <CheckboxCheckedIcon />,
            indeterminateIcon: <CheckboxIndeterminateIcon />,
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              fontSize: '1rem',
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              fontSize: '1rem',
            },
          },
        },
        MuiDataGrid: {
          defaultProps: {
            columnSortedAscendingIcon: <FontAwesomeIcon icon={faChevronUp} />,
            columnSortedDescendingIcon: (
              <FontAwesomeIcon icon={faChevronDown} />
            ),
          },
          styleOverrides: {
            root: {
              border: 'none',
            },
            columnHeaderTitle: {
              color: 'rgba(0, 0, 0, .6)',
              fontSize: '0.8rem',
              textTransform: 'uppercase',
              fontFamily: displayFont,
              fontWeight: 300,
              whiteSpace: 'nowrap',
            },
          },
        },
      },
    } as DashboardThemeOptions,
    localization,
  ) as DashboardTheme;

export default createTheme;
