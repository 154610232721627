import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import qr from 'qr.js';
import { KeyVal } from '../hooks/api/models';

dayjs.extend(utc);

export const computePage = <T>(
  rows: T[],
  page: number,
  rowsPerPage: number,
): T[] => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

export const getElementValueAsString = <T extends string | KeyVal>(
  element: T,
  elementKey?: T extends KeyVal ? keyof T : never,
): string => {
  if (elementKey) {
    return String(element[elementKey]);
  }

  return element as string;
};

export interface SelectionMapOptions<T extends string | KeyVal> {
  selection: string[] | 'page' | 'all';
  elements: T[];
  elementKey?: T extends KeyVal ? keyof T : never;
  page: number;
  rowsPerPage: number;
}

export const getElementTuple = <T extends string | KeyVal>(
  element: T,
  elementKey?: T extends KeyVal ? keyof T : never,
): [string, T] => [getElementValueAsString(element, elementKey), element];

export const computeSelectionKeyVal = <T extends string | KeyVal>({
  selection,
  elements,
  elementKey,
  page,
  rowsPerPage,
}: SelectionMapOptions<T>): KeyVal<T> => {
  if (!selection) {
    return {};
  }

  if (selection === 'page') {
    return Object.fromEntries(
      computePage(elements, page, rowsPerPage).map(element =>
        getElementTuple(element, elementKey),
      ),
    );
  }

  const elementsKeyVal = Object.fromEntries(
    elements.map(element => getElementTuple(element, elementKey)),
  );

  if (selection === 'all') {
    return elementsKeyVal;
  }

  return Object.fromEntries(
    selection
      .filter(id => elementsKeyVal[id])
      .map(id => [id, elementsKeyVal[id]]),
  );
};

export const computeArrQueryParam = (
  arr: unknown[],
  totalElements?: number,
): 'all' | string | null => {
  if (arr.length === 0) {
    return null;
  }

  if (typeof totalElements !== 'undefined' && arr.length === totalElements) {
    return 'all';
  }

  return arr.join(',');
};

export const canvasToBlob = (
  canvas: HTMLCanvasElement,
  type?: string,
  quality?: number,
): Promise<Blob> =>
  new Promise((resolve, reject) =>
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Couldn't create Blob"));
        }
      },
      type,
      quality,
    ),
  );

export const immutableSplice = <T>(
  array: T[],
  ...args: Parameters<T[]['splice']>
): ReturnType<T[]['splice']> => {
  // Splice mutates the original array, so we need to clone it to avoid unwanted side effects
  // For some unknown reason, slice is the fastest way to clone an array in JS
  const result = array.slice(0);
  result.splice(...args);
  return result;
};

export const removeElementAtIndex = <T>(array: T[], index: number): T[] =>
  immutableSplice(array, index, 1);

export const replaceElementAtIndex = <T>(
  array: T[],
  index: number,
  newValue: T,
): T[] => immutableSplice(array, index, 1, newValue);

export const joinPhrases = (t: TFunction, ...phrases: string[]): string => {
  // No phrases provided, return an empty string
  if (phrases.length === 0) {
    return '';
  }
  // Only one phrase is provided, return it
  if (phrases.length === 1) {
    return phrases[0];
  }

  const joiner = t('and');

  // Only two phrases provided, join them with an "and" without an Oxford comma
  if (phrases.length === 2) {
    return `${phrases[0]} ${joiner} ${phrases[1]}`;
  }

  // More than two phrases provided, join the first of them with a comma
  // and the last one with an "and" and with an Oxford comma
  const allPhrasesBesidesLast = phrases.slice(0, -1).join(', ');
  const lastPhrase = phrases[phrases.length - 1];

  return `${allPhrasesBesidesLast}, ${joiner} ${lastPhrase}`;
};

export const filterDuplicates = <T, K extends keyof T>(
  array: T[],
  ...keys: K[]
): T[] => {
  // If no keys are provided, let JS try to detect duplicates on its own
  if (keys.length === 0) {
    return Array.from(new Set(array));
  }

  return array.filter(
    (item, index, a) =>
      a.findIndex(t => keys.every(key => item[key] === t[key])) === index,
  );
};

export const compareStrings = (
  a: string | undefined,
  b: string | undefined,
  revertCoefficient = 1,
): number => {
  if (!a) {
    return Infinity;
  }
  if (!b) {
    return -Infinity;
  }
  return a.localeCompare(b) * revertCoefficient;
};

export const compareNumbers = (
  a: number | undefined,
  b: number | undefined,
  revertCoefficient = 1,
): number => {
  if (typeof a === 'undefined') {
    return Infinity;
  }
  if (typeof b === 'undefined') {
    return -Infinity;
  }
  return (a - b) * revertCoefficient;
};

export const compareIsoDates = (
  a: string | undefined,
  b: string | undefined,
  revertCoefficient = 1,
): number => {
  if (!a) {
    return Infinity;
  }
  if (!b) {
    return -Infinity;
  }
  const parsedA = Date.parse(a);
  if (isNaN(parsedA)) {
    return Infinity;
  }
  const parsedB = Date.parse(b);
  if (isNaN(parsedB)) {
    return -Infinity;
  }
  return (parsedA - parsedB) * revertCoefficient;
};

export const loadImage = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('reject', () => reject());
    img.src = src;
  });

export const generateQr = (data: string): HTMLCanvasElement => {
  const { modules } = qr(data);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error("Couldn't generate QR code");
  }

  const size = modules.length;

  canvas.width = size;
  canvas.height = size;

  modules.forEach((row, x) =>
    row.forEach((cell, y) => {
      ctx.fillStyle = cell ? '#000' : '#fff';
      ctx.fillRect(x, y, 1, 1);
    }),
  );

  return canvas;
};

export type TemperatureUnit = 'C' | 'F' | 'K';

export const celsiusToFahrenheit = (celsius: number): number =>
  (celsius * 9) / 5 + 32;

export const celsiusToKelvin = (celsius: number): number => celsius + 273.15;

export const convertTemperature = (
  temperatureInCelsius: number,
  unit: TemperatureUnit,
): number => {
  switch (unit) {
    case 'C':
      return temperatureInCelsius;
    case 'F':
      return celsiusToFahrenheit(temperatureInCelsius);
    case 'K':
      return celsiusToKelvin(temperatureInCelsius);
  }
};

/**
 * An helper function that generates a random floating point number between 0 and 1.
 * The difference between this function and Math.random() is that this function uses
 * the Crypto module to generate a cryptographically secure random number if available.
 */
export const generateRandomFloat = (): number => {
  // If the Crypto module is available, use it to generate a cryptographically secure random number
  if (window.crypto && window.crypto.getRandomValues) {
    const randomBuffer = new Uint32Array(1);
    window.crypto.getRandomValues(randomBuffer);
    return randomBuffer[0] / (0xffffffff + 1);
  }

  // For older browsers, fallback to the standard Math.random()
  return Math.random();
};

/**
 * An helper function that generates a random integer, optionally between a min and max value.
 * Min defaults to 0, while max defaults to the maximum safe integer in JS (2^53 - 1).
 */
export const generateRandomInt = (
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
): number => {
  const randomFloat = generateRandomFloat();
  return Math.floor(randomFloat * (max - min + 1)) + min;
};

/**
 * An helper function to get random elements from an array.
 * Note: random elements might be repeated.
 */
export const getRandomArrayElements = <T = unknown>(
  // We also allow providing a string instead of an array.
  // This type allows to tell TypeScript that, if the generic is a string,
  // then we also allow an element instead of an array of elements.
  array: T extends string ? T | T[] : T[],
  elements = 1,
): T[] =>
  Array.from(
    { length: elements },
    () => array[generateRandomInt(0, array.length - 1)],
  ) as T[];

/**
 * An helper function that returns a shuffled version of the provided array.
 * The original array is not modified.
 */
export const shuffleArray = <T = unknown>(array: T[]): T[] => {
  const newArr = array.slice();
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = generateRandomInt(0, i);
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr;
};

/**
 * An helper function that generates a random password according to our password policy.
 * Optionally accepts a length (defaults to 12).
 */
export const generateRandomPassword = (length = 12): string => {
  const availableSets = [
    '!@#$%^&*()-_+=.:,;/?\\|[]<>',
    '0123456789',
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    'abcdefghijklmnopqrstuvwxyz',
  ];

  if (length < availableSets.length) {
    throw new Error(`Password length must be at least ${availableSets.length}`);
  }

  const charactersPerSet = Math.floor(length / availableSets.length);
  const extraCharacters = length % availableSets.length;

  const password = [
    // Get the random characters available for each set
    ...availableSets.flatMap(set =>
      getRandomArrayElements(set, charactersPerSet),
    ),
    // The remaining characters are added from the last set,
    getRandomArrayElements(
      availableSets[availableSets.length - 1],
      extraCharacters,
    ).join(''),
  ];

  // Return a shuffled version of the resulting array
  return shuffleArray(password).join('');
};

// Type-only helper that converts a kebab-case type to a camelCase one
export type KebabToCamelCase<S extends string> =
  S extends `${infer T}-${infer U}`
    ? `${T}${Capitalize<KebabToCamelCase<U>>}`
    : S;

export const kebabToCamelCase = <S extends string>(
  str: S,
): KebabToCamelCase<S> =>
  str
    .replace(/-(.)/g, (_, match: string) => match.toUpperCase())
    .replace(/\./g, '') as KebabToCamelCase<S>;

// Type-only helper that converts a snake_case type to a camelCase one
export type SnakeToCamelCase<S extends string> =
  S extends `${infer T}_${infer U}`
    ? `${Lowercase<T>}${Capitalize<SnakeToCamelCase<U>>}`
    : Lowercase<S>;

export const snakeToCamelCase = <S extends string>(
  str: S,
): SnakeToCamelCase<S> =>
  str
    .toLowerCase()
    .replace(/_(.)/g, (_, match: string) => match.toUpperCase())
    .replace(/\./g, '') as SnakeToCamelCase<S>;

/**
 * An helper function that takes a function and a time (in ms) as parameters,
 * and returns a debounced version of that function that resolves with the
 * result of the function when it gets executed.
 */
export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number,
) => {
  let timeout: number;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (timeout) {
        window.clearTimeout(timeout);
      }

      timeout = window.setTimeout(
        () => resolve(func(...args) as ReturnType<F>),
        waitFor,
      );
    });
};

export const formatTime = (time: number): [number, number] => {
  const formattedMinutes = Math.floor(time % 60);
  const formattedHours = Math.floor(time / 60);

  return [formattedHours, formattedMinutes];
};

export const getSignedUrlExpirationDate = (signedUrl: string | URL): Date => {
  const url = new URL(signedUrl);
  const issueDate = url.searchParams.get('X-Amz-Date') || '';
  const expiresIn = url.searchParams.get('X-Amz-Expires') || '';

  if (!issueDate || !expiresIn) {
    throw Error('Invalid signed URL, cannot parse expiration date');
  }

  const parsedIssueDate = dayjs(issueDate, "YYYYMMDD'T'HHmmSSZ").local();

  return parsedIssueDate.add(Number(expiresIn), 's').toDate();
};

export const blobToDataUrl = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(blob);
  });

export const isIncludedInEnum = <T extends Record<string, string | number>>(
  enumerator: T,
) => {
  const values = Object.values(enumerator);
  return (value: unknown) => values.includes(value as T[keyof T]);
};

export const chunk = <T extends string | unknown[]>(
  arr: T,
  size: number,
): T[] =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
    arr.slice(i * size, i * size + size),
  ) as T[];
