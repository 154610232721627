import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import dayjs from 'dayjs';
import {
  Chart,
  LineElement,
  PointElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import minMax from 'dayjs/plugin/minMax';
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import useTheme from '../hooks/useTheme';
import APIProvider from '../providers/APIProvider';
import AuthProvider from '../providers/AuthProvider';
import ToastNotificationsProvider from '../providers/ToastNotificationsProvider';
import MenuProvider from '../providers/MenuProvider';
import ServiceWorkerProvider from '../providers/ServiceWorkerProvider';
import SettingsProvider from '../providers/SettingsProvider';
import MonitoringProvider from '../providers/MonitoringProvider';
import RouterProvider from '../providers/RouterProvider';
import { appDisplayName } from '../config/constants';
import Router from './Router';
import Placeholder from './common/Placeholder';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(minMax);

Chart.register(
  LineElement,
  PointElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  annotationPlugin,
);

const App: FunctionComponent = () => {
  const { theme } = useTheme();
  const { t, i18n, ready } = useTranslation('translation', {
    useSuspense: false,
  });

  return (
    <HelmetProvider>
      <Placeholder
        title={`${appDisplayName} | ${ready ? t('loading') : 'Loading…'}`}
      >
        <ServiceWorkerProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider
                locale={i18n.language}
                dateAdapter={AdapterDayjs}
              >
                <APIProvider baseUrl={import.meta.env.VITE_API_URL}>
                  <AuthProvider>
                    <SettingsProvider>
                      <RouterProvider>
                        <MonitoringProvider>
                          <ToastNotificationsProvider>
                            <MenuProvider>
                              <Helmet
                                defaultTitle={appDisplayName}
                                titleTemplate={`${appDisplayName} | %s`}
                              />
                              <CssBaseline />
                              <Router />
                            </MenuProvider>
                          </ToastNotificationsProvider>
                        </MonitoringProvider>
                      </RouterProvider>
                    </SettingsProvider>
                  </AuthProvider>
                </APIProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ServiceWorkerProvider>
      </Placeholder>
    </HelmetProvider>
  );
};

export default App;
