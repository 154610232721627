import React, {
  FunctionComponent,
  ReactNode,
  Suspense,
  SuspenseProps,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import CenteredSpinner from './CenteredSpinner';

export interface PlaceholderProps extends Omit<SuspenseProps, 'fallback'> {
  title?: string;
  fallback?: ReactNode;
}

/**
 * A component that wrap React Suspense component, using a `CenteredSpinner` as default fallback
 * and adding the possibility to set the page title while loading (defaulting to "Loading…")
 */
const Placeholder: FunctionComponent<PlaceholderProps> = ({
  title,
  fallback = <CenteredSpinner />,
  ...props
}) => {
  const { t, ready } = useTranslation('translation', { useSuspense: false });

  // While locale files are loading, show "Loading" in English
  const defaultTitle = ready ? t('loading') : 'Loading…';

  return (
    <Suspense
      fallback={
        <>
          <Helmet>
            <title>{title || defaultTitle}</title>
          </Helmet>

          {fallback}
        </>
      }
      {...props}
    />
  );
};

export default Placeholder;
