import React, { FunctionComponent } from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

const CheckboxCheckedIcon: FunctionComponent<SvgIconProps> = props => {
  const theme = useTheme();

  return (
    <SvgIcon viewBox="0 0 19 20" {...props}>
      <path d="M0 4.63C0 2.41 1.8.6 4.03.6h10.94C17.2.6 19 2.4 19 4.63v10.94c0 2.23-1.8 4.03-4.03 4.03H4.03A4.03 4.03 0 010 15.57V4.63z" />
      <path
        fill={theme.palette.primary.contrastText}
        d="M15.11 5.74l-7.1 7.11-2.52-2.51a.34.34 0 00-.43 0l-.28.3c-.13.1-.13.3 0 .43L7.8 14.1c.1.1.3.1.4 0l7.62-7.61c.13-.13.13-.33 0-.43l-.28-.3a.34.34 0 00-.43 0z"
      />
    </SvgIcon>
  );
};

export default CheckboxCheckedIcon;
