import { PartialPath } from 'history';
import { KeyVal } from '../hooks/api/models';

export type ParamType = string | number | boolean;

export type ParamsMap = KeyVal<ParamType | ParamType[] | null>;

export const mergeSearchParams = (
  previousParams: string | URLSearchParams,
  newParams: ParamsMap,
): URLSearchParams => {
  const parsedPreviousParams = new URLSearchParams(previousParams);

  Object.entries(newParams).forEach(([key, val]) => {
    if (val === null) {
      parsedPreviousParams.delete(key);
    } else {
      parsedPreviousParams.set(
        key,
        Array.isArray(val) ? val.join(',') : val.toString(),
      );
    }
  });

  return parsedPreviousParams;
};

export const parsePartialPath = (url: string): PartialPath => {
  const [rest, hash] = url.split('#');
  const [pathname, search] = rest.split('?') || [];

  return {
    pathname,
    search: search && search.length > 0 ? `?${search}` : '',
    hash: hash && hash.length > 0 ? `#${hash}` : '',
  };
};
