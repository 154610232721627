import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Toaster } from 'react-hot-toast';
import { GlobalStyles, GlobalStylesProps } from '@mui/material';
import useTheme from '../hooks/useTheme';

enum ToastClass {
  ROOT = 'RhtToast-root',
  SUCCESS = 'RhtToast-success',
  LOADING = 'RhtToast-loading',
  ERROR = 'RhtToast-error',
}

const getSelector = (type: ToastClass) => `#root .${type}`;

const ToastNotificationsProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { theme } = useTheme();

  return (
    <>
      <GlobalStyles
        styles={
          {
            [getSelector(ToastClass.ROOT)]: {
              ...theme.typography.caption,
              borderRadius: theme.shape.borderRadius,
            },
            [getSelector(ToastClass.SUCCESS)]: {
              background: theme.palette.success.main,
              color: theme.palette.success.contrastText,
            },
            [getSelector(ToastClass.LOADING)]: {
              background: theme.palette.info.main,
              color: theme.palette.info.contrastText,
            },
            [getSelector(ToastClass.ERROR)]: {
              background: theme.palette.error.main,
              color: theme.palette.error.contrastText,
            },
          } as GlobalStylesProps['styles']
        }
      />
      <Toaster
        toastOptions={{
          icon: null,
          duration: 5000,
          success: { className: `${ToastClass.ROOT} ${ToastClass.SUCCESS}` },
          loading: { className: `${ToastClass.ROOT} ${ToastClass.LOADING}` },
          error: { className: `${ToastClass.ROOT} ${ToastClass.ERROR}` },
        }}
      />
      {children}
    </>
  );
};

export default ToastNotificationsProvider;
