import { useCallback, SetStateAction } from 'react';
import { useLocation, resolvePath } from 'react-router-dom';
import { Path } from 'history';
import { mergeSearchParams, ParamsMap, parsePartialPath } from '../helpers/url';

export interface UseLinkUtilsResult {
  /**
   * Changes the current path, preserving search params and hash.
   * Similarly to `useState`, it supports as parameter both a path
   * and a function taking as input the previous path and returning
   * the new path. It also accepts the current location as optional
   * third parameter (defaults to the current location).
   */
  changePath(
    this: void,
    path: SetStateAction<string>,
    location?: Partial<Path> | string,
  ): string;
  /**
   * Adds search params to the current path
   * Similarly to `useState`, it supports as parameter both a params
   * object and a function taking as input the previous params object and
   * returning the new params object. It also accepts the current location
   * as optional third parameter (defaults to the current location).
   */
  addSearchParams(
    this: void,
    params: SetStateAction<ParamsMap>,
    location?: Partial<Path> | string,
  ): string;
}

/** A hook that provides utility functions for links handling */
const useLinkUtils = (): UseLinkUtilsResult => {
  const currentLocation = useLocation();

  const changePath = useCallback<UseLinkUtilsResult['changePath']>(
    (path, rawLocation = currentLocation) => {
      const location =
        typeof rawLocation === 'string'
          ? parsePartialPath(rawLocation)
          : rawLocation;

      const newPath =
        typeof path === 'function' ? path(location.pathname || '') : path;

      const resolvedPath = resolvePath(
        {
          pathname: newPath,
          search: location.search || '',
          hash: location.hash || '',
        },
        location.pathname,
      );

      return `${resolvedPath.pathname}${resolvedPath.search || ''}${
        resolvedPath.hash || ''
      }`;
    },
    // We are setting the entire currentLocation object as the default value for location,
    // but we actually only care about its pathname, hash, and search fields. ESLint has
    // no way to know it, so we need to disable this rule
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLocation.pathname, currentLocation.hash, currentLocation.search],
  );

  const addSearchParams = useCallback<UseLinkUtilsResult['addSearchParams']>(
    (params, rawLocation = currentLocation) => {
      const location =
        typeof rawLocation === 'string'
          ? parsePartialPath(rawLocation)
          : rawLocation;

      const currentSearchParams = Object.fromEntries(
        new URLSearchParams(location.search),
      );

      const newParams =
        typeof params === 'function' ? params(currentSearchParams) : params;

      const newParamsString = mergeSearchParams(
        currentLocation.search,
        newParams,
      ).toString();

      const resolvedPath = resolvePath(
        {
          pathname: currentLocation.pathname,
          search: newParamsString ? `?${newParamsString}` : '',
          hash: currentLocation.hash || '',
        },
        location.pathname,
      );

      return `${resolvedPath.pathname}${resolvedPath.search || ''}${
        resolvedPath.hash || ''
      }`;
    },
    // Same as above
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLocation.pathname, currentLocation.hash, currentLocation.search],
  );

  return { changePath, addSearchParams };
};

export default useLinkUtils;
