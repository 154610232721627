import { ResourceKey } from 'i18next';

const accentedCharsMap: Record<string, string | undefined> = {
  a: 'ȧ',
  A: 'Ȧ',
  b: 'ƀ',
  B: 'Ɓ',
  c: 'ƈ',
  C: 'Ƈ',
  d: 'ḓ',
  D: 'Ḓ',
  e: 'ḗ',
  E: 'Ḗ',
  f: 'ƒ',
  F: 'Ƒ',
  g: 'ɠ',
  G: 'Ɠ',
  h: 'ħ',
  H: 'Ħ',
  i: 'ī',
  I: 'Ī',
  j: 'ĵ',
  J: 'Ĵ',
  k: 'ķ',
  K: 'Ķ',
  l: 'ŀ',
  L: 'Ŀ',
  m: 'ḿ',
  M: 'Ḿ',
  n: 'ƞ',
  N: 'Ƞ',
  o: 'ǿ',
  O: 'Ǿ',
  p: 'ƥ',
  P: 'Ƥ',
  q: 'ɋ',
  Q: 'Ɋ',
  r: 'ř',
  R: 'Ř',
  s: 'ş',
  S: 'Ş',
  t: 'ŧ',
  T: 'Ŧ',
  v: 'ṽ',
  V: 'Ṽ',
  u: 'ŭ',
  U: 'Ŭ',
  w: 'ẇ',
  W: 'Ẇ',
  x: 'ẋ',
  X: 'Ẋ',
  y: 'ẏ',
  Y: 'Ẏ',
  z: 'ẑ',
  Z: 'Ẑ',
};

const rtlCharsMap: Record<string, string | undefined> = {
  a: 'ɐ',
  A: '∀',
  b: 'q',
  B: 'Ԑ',
  c: 'ɔ',
  C: 'Ↄ',
  d: 'p',
  D: 'ᗡ',
  e: 'ǝ',
  E: 'Ǝ',
  f: 'ɟ',
  F: 'Ⅎ',
  g: 'ƃ',
  G: '⅁',
  h: 'ɥ',
  H: 'H',
  i: 'ı',
  I: 'I',
  j: 'ɾ',
  J: 'ſ',
  k: 'ʞ',
  K: 'Ӽ',
  l: 'ʅ',
  L: '⅂',
  m: 'ɯ',
  M: 'W',
  n: 'u',
  N: 'N',
  o: 'o',
  O: 'O',
  p: 'd',
  P: 'Ԁ',
  q: 'b',
  Q: 'Ò',
  r: 'ɹ',
  R: 'ᴚ',
  s: 's',
  S: 'S',
  t: 'ʇ',
  T: '⊥',
  u: 'n',
  U: '∩',
  v: 'ʌ',
  V: 'Ʌ',
  w: 'ʍ',
  W: 'M',
  x: 'x',
  X: 'X',
  y: 'ʎ',
  Y: '⅄',
  z: 'z',
  Z: 'Z',
};

export type PseudoLocalizationStrategy = 'accented' | 'rtl';

interface PseudoLocalizationConfig {
  prefix: string;
  postfix: string;
  map: Record<string, string | undefined>;
  elongate?: boolean;
}

const strategies: Record<PseudoLocalizationStrategy, PseudoLocalizationConfig> =
  {
    accented: {
      prefix: '',
      postfix: '',
      map: accentedCharsMap,
      elongate: true,
    },
    rtl: {
      // Surround words with Unicode formatting marks forcing
      // right-to-left directionality of characters
      prefix: '\u202e',
      postfix: '\u202c',
      map: rtlCharsMap,
      elongate: false,
    },
  };

export const pseudoLocalizeKey = (
  key: string,
  strategy: PseudoLocalizationStrategy = 'accented',
): string => {
  const { prefix, postfix, map, elongate } = strategies[strategy];

  let isInTag = false;
  let isInInterpolation = false;

  const transformedString = Array.from(key).reduce((newKey, char) => {
    // Handle tags and interpolations opening and closing
    if (char === '<' || char === '>') {
      isInTag = char === '<';
      return newKey + char;
    }
    if (char === '{' || char === '}') {
      isInInterpolation = char === '{';
      return newKey + char;
    }

    const mappedChar = map[char];

    // Don't transform anything inside tags or interpolations or anything that is not a letter
    if (isInTag || isInInterpolation || !mappedChar) {
      return `${newKey}${char}`;
    }

    // Duplicate vowels to emulate ~40% longer text if elongate option is true
    const charToAdd = mappedChar.repeat(
      elongate && /[aeiouy]/i.test(char) ? 2 : 1,
    );

    return `${newKey}${charToAdd}`;
  }, '');

  return `${prefix}${transformedString}${postfix}`;
};

export const pseudoLocalizeResource = (
  resource: ResourceKey,
  strategy: PseudoLocalizationStrategy = 'accented',
): ResourceKey => {
  if (typeof resource === 'string') {
    return pseudoLocalizeKey(resource, strategy);
  }

  return Object.fromEntries(
    Object.entries(resource).map(([key, val]) => [
      key,
      pseudoLocalizeKey(val as string, strategy),
    ]),
  );
};
