import React, { FunctionComponent } from 'react';
import { BrowserRouter, BrowserRouterProps } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { basename } from '../config/constants';

export const history = createBrowserHistory();

export { Routes, Route } from 'react-router-dom';

export type RouterProviderProps = Omit<BrowserRouterProps, 'basename'>;

const RouterProvider: FunctionComponent<RouterProviderProps> = props => (
  <BrowserRouter basename={basename} {...props} />
);

export default RouterProvider;
