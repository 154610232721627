import React, {
  FunctionComponent,
  createContext,
  useContext,
  PropsWithChildren,
} from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { KeyVal } from '../hooks/api/models';

export interface APIProviderProps {
  baseUrl?: string;
}

export interface APIContextValue {
  baseUrl: string;
}

export interface LegacyAPIResponse<TPayload = unknown> {
  status: string;
  statusText: string;
  payload: TPayload;
}

export interface APIRequestOptions extends Omit<RequestInit, 'body'> {
  auth?: boolean | string;
  query?: KeyVal<string | number | (string | number)[]>;
  body?: KeyVal<unknown>;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // Retry the queries once before throwing the error
      retry: 1,
    },
  },
});

export const APIContext = createContext<APIContextValue>({
  baseUrl: '/',
});

export const APIProvider: FunctionComponent<
  PropsWithChildren<APIProviderProps>
> = ({ baseUrl = '/', children }) => (
  <APIContext.Provider value={{ baseUrl }}>
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  </APIContext.Provider>
);

export const useAPI = (): APIContextValue => useContext(APIContext);

export default APIProvider;
