import React, {
  FunctionComponent,
  createContext,
  useCallback,
  useContext,
  useState,
  PropsWithChildren,
} from 'react';
import decodeJwt from 'jwt-decode';
import {
  accessTokenKey,
  appEnvironment,
  appShortName,
  refreshTokenKey,
  userKey,
} from '../config/constants';
import { APIContext, LegacyAPIResponse } from './APIProvider';
import { mergeUrlParts, request } from '../helpers/api';
import { KeyVal, Portal } from '../hooks/api/models';
import {
  KebabToCamelCase,
  kebabToCamelCase,
  SnakeToCamelCase,
  snakeToCamelCase,
} from '../helpers/utils';

export enum LoginError {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  UNPROCESSABLE_RESPONSE = 'UNPROCESSABLE_RESPONSE',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum LogoutError {
  UNPROCESSABLE_RESPONSE = 'UNPROCESSABLE_RESPONSE',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum UserRole {
  // Role for Empatica Admin users
  ADMIN = 'admin',
  // Role for Research Portal Owners
  OWNER = 'owner',
  // Role for Empatica Data Team Member users
  DATA_TEAM_MEMBER = 'data-team-member',
  // Role for the Technical Support Team members
  TECHNICAL_SUPPORT = 'technical-support',
  // Role for Research Portal Viewers
  RESEARCH_VIEWER = 'research-viewer',
  // Role required to create enrollments
  ENROLLMENT_CREATOR = 'enrollment-creator',
  // Role for Site Viewers
  SITE_VIEWER = 'site-viewer',
  // Role for Study Viewers
  STUDY_VIEWER = 'study-viewer',
  // Role for Organization Viewers
  ORGANIZATION_VIEWER = 'organization-viewer',
  // Role for Site Admins
  SITE_ADMIN = 'site-admin',
  // Role for Study Admins
  STUDY_ADMIN = 'study-admin',
  // Role for Organization Admins
  ORGANIZATION_ADMIN = 'organization-admin',
  // Role for Site Managers
  SITE_MANAGER = 'site-manager',
  // Role for Study Managers
  STUDY_MANAGER = 'study-manager',
  // Role for Organization Managers
  ORGANIZATION_MANAGER = 'organization-manager',
  // Role for Site Owners
  SITE_OWNER = 'site-owner',
  // Role for Study Owners
  STUDY_OWNER = 'study-owner',
  // Role for Organization Owners
  ORGANIZATION_OWNER = 'organization-owner',
  // Role for OPS Managers
  OPERATIONS_MANAGER = 'operations-manager',
  // Role for OPS Viewers
  OPERATIONS_VIEWER = 'operations-viewer',
}

export interface DecodedAccessToken {
  PAYLOAD: {
    userId: number;
    devicesMap?: KeyVal<string>;
    roles?: UserRole[];
    'scopes.v1'?: string[];
  };
  userVersion: number;
  tokenType: string;
  exp: number;
  iat: number;
  iss: string;
}

export interface UserAgreement {
  id: number;
  userId: number;
  userAgreementId: number;
  acceptedAt: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  userAgreement: {
    id: number;
    name: string;
    description: string;
    active: boolean;
    required: boolean;
    url: string;
    version: string;
    notificationTitle: string;
    notificationMessage: string;
    acceptance: null;
  };
}

export enum ScopeKey {
  ORGANIZATIONS = 'orgs',
  STUDIES = 'studies',
  SITES = 'sites',
}

export enum ScopeValue {
  OWNER = 'owner',
  ADMIN = 'admin',
  MANAGER = 'manager',
  VIEWER = 'viewer',
  NOTIFIED = 'notified',
  DATA_ACCESS_KEYS_MANAGER = 'data-access-keys-manager',
}

export interface Scope {
  key: ScopeKey;
  variant: number;
  value?: ScopeValue;
}

export type ScopeWithoutVariant = Omit<Scope, 'variant'>;
export interface User {
  id: number;
  birthday: string | null;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  gender: string | null;
  countryCode: string | null;
  country: string | null;
  height: string | null;
  weight: string | null;
  timezone: string | null;
  password: string | null;
  createdFrom: string;
  phones: string[];
  caregivers: string[];
  userAccount: {
    userId: number;
    disabled: boolean;
    passwordChangedAt: string;
    lastLoggedAt: string;
    createdAt: string;
  };
  devices: null;
  parentalConsent: null;
  agreements: UserAgreement[];
  portals: Portal[];
  parentalConsentNeeded: boolean;
  hasEmailBounced: boolean;
  devicesMap: KeyVal<string>;
  roles: UserRole[];
  scopes: Scope[];
}

export type GetUserResponse = LegacyAPIResponse<
  Omit<User, 'devicesMap' | 'roles' | 'scopes'>
>;

export type LoginResponse = LegacyAPIResponse<{
  token: string;
  refreshToken: string;
}>;

export type RefreshAccessTokenResponse = LegacyAPIResponse<{
  accessToken: string;
  refreshToken: string;
}>;

export type AccessRule =
  | UserRole
  | UserRole[]
  | Scope
  | Scope[]
  | ScopeWithoutVariant
  | ScopeWithoutVariant[];

export enum LogoutReason {
  INACTIVITY = 'inactivity',
  ACCESS_REVOKED = 'access_revoked',
}

interface BaseAuthContextValue {
  login(this: void, email: string, password: string): Promise<void>;
  logout(this: void, reason?: LogoutReason): Promise<void>;
  refreshAccessToken(
    this: void,
  ): Promise<RefreshAccessTokenResponse['payload']>;
  verifyIdentity(this: void, password: string): Promise<void>;
  refetchUser(
    this: void,
    accessToken?: string,
  ): Promise<{ user: User; decodedAccessToken: DecodedAccessToken }>;
  /**
   * An helper that allows to check if the currently authenticated user has access
   * to a given content. It must be used together with the {@link is} helper.
   *
   * @example checkPermissions(
   *   is.dataTeamMember(),
   *   is.orgs.owner.of(123),
   *   is.sites.viewer(),
   * );
   */
  checkPermissions(this: void, ...rules: AccessRule[]): boolean;
}

interface NotLoggedInAuthContextValue extends BaseAuthContextValue {
  isLoggedIn: false;
  isLoggingIn: boolean;
  isVerifyingIdentity: false;
  accessToken?: never;
  decodedAccessToken?: never;
  refreshToken?: never;
  user?: never;
  logoutReason?: LogoutReason;
}

interface LoggedInAuthContextValue extends BaseAuthContextValue {
  isLoggedIn: true;
  isLoggingIn: false;
  isVerifyingIdentity: boolean;
  accessToken: string;
  decodedAccessToken: DecodedAccessToken;
  refreshToken: string;
  user: User;
  logoutReason?: never;
}

export type AuthContextValue =
  | NotLoggedInAuthContextValue
  | LoggedInAuthContextValue;

type IsScopeResult = (() => ScopeWithoutVariant[]) & {
  of(...variants: number[]): Scope[];
};

/**
 * `is` is a dynamic helper that allows to easily build queries for the
 * `checkPermissions` method of the `useRBAC` hook.
 */
export const is = {
  // For each user scope, add an object into the `is` object that
  // contains all its possible scope values
  ...(Object.values(ScopeKey).reduce(
    (keys, key) => ({
      ...keys,
      [key]: Object.entries(ScopeValue).reduce((values, [valueKey, value]) => {
        // For each possible scope value, we create a special scopeResult function
        // that returns an array containing the scope key and the scope value, but
        // that also contains an `of` method that allows to specify the scope variants.
        // e.g. is.orgs.owner() => [{ key: 'orgs', value: 'owner' }]
        const scopeResult = () => [{ key, value }];
        Object.defineProperty(scopeResult, 'of', {
          // The `of` method can be called with a list of scope variants, and
          // it will return an array containing all the specified variants in
          // an object, together with the scope key and scope value.
          // e.g. is.orgs.owner.of(123, 456) => [{ key: 'orgs', value: 'owner', variant: 123 }, { key: 'orgs', value: 'owner', variant: 456 }]
          value: (...variants: number[]) =>
            variants.map(variant => ({ key, value, variant })),
        });

        return {
          ...values,
          [snakeToCamelCase(valueKey)]: scopeResult,
        };
      }, {}),
    }),
    {},
  ) as Record<
    ScopeKey,
    Record<SnakeToCamelCase<keyof typeof ScopeValue>, IsScopeResult>
  >),
  // Add each user role to the object as a camelCased function that
  // returns the corresponding user role in an array.
  // e.g. is.operationsManager() => [UserRole.OPERATIONS_MANAGER]
  ...(Object.values(UserRole).reduce(
    (acc, role) => ({
      ...acc,
      [kebabToCamelCase(role)]: () => [role],
    }),
    {},
  ) as Record<KebabToCamelCase<UserRole>, () => UserRole>),
};

const extendUser = (
  user: Omit<User, 'devicesMap' | 'roles' | 'scopes'>,
  at: DecodedAccessToken,
): User => ({
  ...user,
  devicesMap: at.PAYLOAD.devicesMap || {},
  roles: at.PAYLOAD.roles || [],
  scopes:
    at.PAYLOAD['scopes.v1']?.map(rawScope => {
      const [key, variant, value] = rawScope.split(':');

      return {
        key: key as ScopeKey,
        variant: Number(variant),
        value: value as ScopeValue,
      };
    }) || [],
});

const getValuesFromStorage = (): Omit<
  AuthContextValue,
  keyof BaseAuthContextValue
> => {
  const accessToken = localStorage.getItem(accessTokenKey);
  const refreshToken = localStorage.getItem(refreshTokenKey);
  const storedUser = localStorage.getItem(userKey);

  if (!accessToken || !refreshToken || !storedUser) {
    return {
      isLoggedIn: false,
      isLoggingIn: false,
      isVerifyingIdentity: false,
    };
  }

  const decodedAccessToken = decodeJwt<DecodedAccessToken>(accessToken);

  return {
    isLoggedIn: true,
    isLoggingIn: false,
    isVerifyingIdentity: false,
    accessToken,
    decodedAccessToken,
    refreshToken,
    user: extendUser(
      JSON.parse(storedUser) as Omit<User, 'devicesMap' | 'roles' | 'scopes'>,
      decodedAccessToken,
    ),
  };
};

let refreshingAccessTokenPromise: ReturnType<
  BaseAuthContextValue['refreshAccessToken']
> | null = null;

export const canAccessPortal = (user?: User): boolean =>
  (appEnvironment !== 'production' && appEnvironment !== 'beta') ||
  // Compatibility for old users
  !user?.portals ||
  user.portals.some(portal => portal?.alias === appShortName);

export const AuthContext = createContext<AuthContextValue | undefined>(
  undefined,
);

export const AuthProvider: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { baseUrl } = useContext(APIContext);
  const [values, setValues] = useState(getValuesFromStorage());

  const performLogin = useCallback(
    async (
      email: string,
      password: string,
    ): Promise<LoginResponse['payload']> => {
      const loginResponse = await request(`${baseUrl}/v3/login`, {
        method: 'POST',
        body: { email, password },
      });

      if (loginResponse.status === 401) {
        throw new Error(LoginError.INVALID_CREDENTIALS);
      }

      const body = (await loginResponse.json()) as LoginResponse;

      if (loginResponse.status !== 200) {
        throw Object.assign(new Error(LoginError.UNPROCESSABLE_RESPONSE), {
          response: {
            status: loginResponse.status,
            body,
          },
        });
      }

      return body.payload;
    },
    [baseUrl],
  );

  const performRefresh = useCallback(async () => {
    if (!values?.refreshToken) {
      throw new Error(LoginError.UNAUTHORIZED);
    }

    const response = await request(
      mergeUrlParts(baseUrl, 'v3/accessToken'),
      {
        method: 'POST',
      },
      values.refreshToken,
    );

    const body = (await response.json()) as RefreshAccessTokenResponse;

    if (response.status !== 200) {
      throw Object.assign(
        new Error(
          response.status === 401
            ? LoginError.UNAUTHORIZED
            : LoginError.UNPROCESSABLE_RESPONSE,
        ),
        {
          response: {
            status: response.status,
            body,
          },
        },
      );
    }

    const {
      payload: { accessToken, refreshToken },
    } = body;

    localStorage.setItem(accessTokenKey, accessToken);
    localStorage.setItem(refreshTokenKey, refreshToken);

    const decodedAccessToken = decodeJwt<DecodedAccessToken>(accessToken);

    setValues(previousValues => ({
      ...previousValues,
      logoutReason: undefined,
      accessToken,
      refreshToken,
      decodedAccessToken,
      // User definitely exists at this point
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      user: extendUser(previousValues.user!, decodedAccessToken),
    }));

    return { accessToken, refreshToken };
  }, [baseUrl, values?.refreshToken]);

  const refreshAccessToken = useCallback<
    AuthContextValue['refreshAccessToken']
  >(async () => {
    // If we don't have a stored refresh access token promise, then we
    // perform the refresh token request and store its promise
    if (!refreshingAccessTokenPromise) {
      refreshingAccessTokenPromise = performRefresh();
    }

    // Wait for the stored promise to resolve, then delete it and return its result
    const response = await refreshingAccessTokenPromise;
    refreshingAccessTokenPromise = null;
    return response;
  }, [performRefresh]);

  const refetchUser = useCallback<AuthContextValue['refetchUser']>(
    async (accessToken = values?.accessToken) => {
      if (!accessToken) {
        throw new Error('User is not authenticated');
      }

      const decodedAccessToken = decodeJwt<DecodedAccessToken>(accessToken);

      let userResponse = await request(
        `${baseUrl}/v3/users/${decodedAccessToken.PAYLOAD.userId}`,
        undefined,
        accessToken,
      );

      // If the token is the default one and the response is a 401, it means
      // we need to refresh it and try again the request with the new one
      if (accessToken === values?.accessToken && userResponse.status === 401) {
        let newAccessToken;
        try {
          const res = await refreshAccessToken();
          newAccessToken = res.accessToken;
        } catch {
          throw new Error('User is not authenticated');
        }

        userResponse = await request(
          `${baseUrl}/v3/users/${decodedAccessToken.PAYLOAD.userId}`,
          undefined,
          newAccessToken,
        );
      }

      const body = (await userResponse.json()) as GetUserResponse;

      if (userResponse.status !== 200) {
        throw Object.assign(new Error(LoginError.UNPROCESSABLE_RESPONSE), {
          response: {
            status: userResponse.status,
            body,
          },
        });
      }

      const { payload: user } = body;

      localStorage.setItem(userKey, JSON.stringify(user));

      const extendedUser = extendUser(user, decodedAccessToken);

      setValues(previousValues => ({
        ...previousValues,
        logoutReason: undefined,
        accessToken,
        decodedAccessToken,
        user: extendedUser,
      }));

      return {
        user: extendedUser,
        decodedAccessToken,
      };
    },
    [baseUrl, refreshAccessToken, values?.accessToken],
  );

  const login = useCallback<AuthContextValue['login']>(
    async (email, password) => {
      if (
        values.isLoggedIn ||
        values.isLoggingIn ||
        values.isVerifyingIdentity
      ) {
        return;
      }

      setValues(previousValues => ({
        ...previousValues,
        logoutReason: undefined,
        isLoggingIn: true,
      }));

      try {
        const { token: accessToken, refreshToken } = await performLogin(
          email,
          password,
        );

        if (!accessToken || !refreshToken) {
          return;
        }

        const { user, decodedAccessToken } = await refetchUser(accessToken);

        if (!canAccessPortal(user)) {
          throw new Error(LoginError.UNAUTHORIZED);
        }

        localStorage.setItem(accessTokenKey, accessToken);
        localStorage.setItem(refreshTokenKey, refreshToken);

        setValues({
          isLoggedIn: true,
          isLoggingIn: false,
          isVerifyingIdentity: false,
          accessToken,
          decodedAccessToken,
          refreshToken,
          user,
        });
      } catch (error) {
        setValues({
          isLoggedIn: false,
          isLoggingIn: false,
          isVerifyingIdentity: false,
        });

        throw error;
      }
    },
    [
      performLogin,
      refetchUser,
      values.isLoggedIn,
      values.isLoggingIn,
      values.isVerifyingIdentity,
    ],
  );

  const logout = useCallback<AuthContextValue['logout']>(
    async reason => {
      localStorage.removeItem(accessTokenKey);
      localStorage.removeItem(refreshTokenKey);
      localStorage.removeItem(userKey);

      if (!values?.user || !values?.accessToken) {
        return;
      }

      await request(
        `${baseUrl}/v3/users/${
          values.user.id
        }/logout?verification=${window.btoa(values.user.email)}`,
        { method: 'POST' },
        values.accessToken,
      );

      setValues({
        isLoggedIn: false,
        isLoggingIn: false,
        isVerifyingIdentity: false,
        logoutReason: reason,
      });
    },
    [baseUrl, values?.accessToken, values?.user],
  );

  const verifyIdentity = useCallback<AuthContextValue['verifyIdentity']>(
    async password => {
      if (!values.user?.email) {
        throw new Error();
      }

      setValues(previousValues => ({
        ...previousValues,
        isVerifyingIdentity: true,
      }));

      try {
        await performLogin(values.user.email, password);
        setValues(previousValues => ({
          ...previousValues,
          isVerifyingIdentity: false,
        }));
      } catch (error) {
        setValues(previousValues => ({
          ...previousValues,
          isVerifyingIdentity: false,
        }));
        throw error;
      }
    },
    [performLogin, values.user?.email],
  );

  const checkPermissions = useCallback<AuthContextValue['checkPermissions']>(
    (...rules) => {
      if (!values.user) {
        return false;
      }

      return rules.flat().some(rule =>
        typeof rule === 'string'
          ? // The rule is a role
            values.user?.roles.includes(rule)
          : // The rule is a scope
            values.user?.scopes.some(
              scope =>
                rule.key === scope.key &&
                rule.value === scope.value &&
                // Only match the variant if it has been specified
                (!('variant' in rule) || rule.variant === scope.variant),
            ),
      );
    },
    [values.user],
  );

  return (
    <AuthContext.Provider
      value={
        {
          login,
          logout,
          refreshAccessToken,
          verifyIdentity,
          checkPermissions,
          refetchUser,
          ...values,
        } as AuthContextValue
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextValue => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return authContext;
};

export default AuthProvider;
