import React, { FunctionComponent, lazy, useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Snackbar } from '@mui/material';
import { useAuth } from '../providers/AuthProvider';
import { useServiceWorker } from '../providers/ServiceWorkerProvider';
import useLinkUtils from '../hooks/useLinkUtils';
import LoadableButton from './common/LoadableButton';

const AuthenticatedUserRouter = lazy(
  () => import('./auth/AuthenticatedUserRouter'),
);
const AuthRouter = lazy(() => import('./auth/AuthRouter'));
const LegacyCareReportRedirect = lazy(
  () => import('./reports/adherence/LegacyCareReportRedirect'),
);
const WearingDetectionReport = lazy(
  () => import('./reports/adherence/WearingDetectionReport'),
);

const publicPaths = [
  '/login',
  '/reset-password',
  '/create-account',
  '/reports/adherence',
  '/reports/wearing-detection',
];

const Router: FunctionComponent = () => {
  const { isLoggedIn } = useAuth();
  const { pathname, search } = useLocation();
  const { changePath, addSearchParams } = useLinkUtils();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateReady, update } = useServiceWorker();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (
      !isLoggedIn &&
      !publicPaths.some(authRouterPath => pathname.startsWith(authRouterPath))
    ) {
      const shouldRedirect = pathname !== '/' && pathname !== '/logout';

      navigate(
        changePath(
          '/login',
          shouldRedirect
            ? addSearchParams({ redirectTo: `${pathname}${search}` })
            : undefined,
        ),
        { replace: true },
      );
    }
  }, [addSearchParams, changePath, isLoggedIn, navigate, pathname, search]);

  const handleUpdate = () => {
    setIsUpdating(true);
    update();
  };

  return (
    <Routes>
      <Route
        path="/reports/adherence/*"
        element={<LegacyCareReportRedirect />}
      />
      <Route
        path="/reports/wearing-detection/*"
        element={<WearingDetectionReport />}
      />
      <Route
        path="*"
        element={
          <>
            {isLoggedIn ? <AuthenticatedUserRouter /> : <AuthRouter />}
            <Snackbar
              open={updateReady}
              message={t('update_available')}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              action={
                <LoadableButton
                  color="inherit"
                  size="small"
                  loading={isUpdating}
                  onClick={handleUpdate}
                >
                  {t('update_now')}
                </LoadableButton>
              }
            />
          </>
        }
      />
    </Routes>
  );
};

export default Router;
