import React, { FunctionComponent, ReactNode } from 'react';
import {
  Button as MuiButton,
  buttonClasses,
  ButtonProps,
  CircularProgress,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { styled } from '../../themes/common';

export interface LoadableButtonProps extends ButtonProps {
  loading?: boolean;
  tooltip?: ReactNode;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
}

const Button = styled(MuiButton, {
  shouldForwardProp: prop => prop !== 'loading',
})<LoadableButtonProps>(({ loading }) => ({
  paddingLeft: 0,
  paddingRight: 0,

  ...(loading && {
    pointerEvents: 'none',
  }),

  [`& .${buttonClasses.startIcon}`]: {
    marginLeft: 8,
    marginRight: 4,

    '&, & > svg': {
      width: 16,
      height: 16,
      fontSize: 16,
    },
  },

  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: 4,
    marginRight: 8,

    '&, & > svg': {
      width: 16,
      height: 16,
      fontSize: 16,
    },
  },
}));

const IconPlaceholder = styled('div')({
  width: 16,
});

/**
 * A Material UI button with an extra `loading` prop that allows to
 * disable the button and show a loader on it
 */
const LoadableButton: FunctionComponent<LoadableButtonProps> = ({
  startIcon,
  endIcon,
  tooltip,
  tooltipProps,
  loading,
  ...props
}) => {
  const content = (
    <Button
      startIcon={
        loading ? (
          <CircularProgress size={16} color="inherit" />
        ) : (
          startIcon || <IconPlaceholder />
        )
      }
      endIcon={endIcon || <IconPlaceholder />}
      {...props}
    />
  );

  return tooltip ? (
    <Tooltip title={tooltip} {...tooltipProps}>
      <div>{content}</div>
    </Tooltip>
  ) : (
    content
  );
};

export default LoadableButton;
