import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// We could lazy-load these locales. However, we would
// lose tree shaking on the imported file, so it is more
// beneficial to just import them (at least for now that we only have 2 locales)
import { enUS, itIT, Localization } from '@mui/material/locale';
import { createLightTheme } from '../themes/light';
import { createDarkTheme } from '../themes/dark';
import { themeKey } from '../config/constants';
import { DashboardTheme } from '../themes/common';

export interface UseThemeValue {
  theme: DashboardTheme;
}

const localeToMUILocale: Record<string, Localization> = {
  en: enUS,
  it: itIT,
};

const createTheme = (localization?: Localization) =>
  localStorage.getItem(themeKey) === 'dark'
    ? createDarkTheme(localization)
    : createLightTheme(localization);

const initialTheme = createTheme();

const useTheme = (): UseThemeValue => {
  const [theme, setTheme] = useState<DashboardTheme>(initialTheme);
  const { i18n, ready } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    if (!ready) {
      return;
    }

    const muiLocale = localeToMUILocale[i18n.language];

    // Helper function to allow developers and designers to
    // switch to the dark theme and debug it
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).toggleDarkTheme = () => {
      const isDark = localStorage.getItem(themeKey) === 'dark';
      setTheme(
        isDark ? createLightTheme(muiLocale) : createDarkTheme(muiLocale),
      );
      localStorage.setItem(themeKey, isDark ? 'light' : 'dark');
    };

    setTheme(createTheme(muiLocale));
  }, [i18n.language, ready]);

  return { theme };
};

export default useTheme;
