export const appEnvironment =
  // Allow to override the environment via Cypress
  window.Cypress?.env('E2E_APP_ENVIRONMENT') ||
  import.meta.env.VITE_APP_ENVIRONMENT ||
  'dev';
export const appName = `web-care-dashboard-${appEnvironment}`;
export const appShortName =
  appEnvironment === 'production' ? 'care' : 'care-beta';
export const appDisplayName =
  appEnvironment === 'production' ? 'Care Portal' : 'Care Portal - Beta';
export const appVersion = import.meta.env.VITE_APP_VERSION || 'dev';
export const appPlatform = 'web';
export const accessTokenKey = 'at';
export const refreshTokenKey = 'rt';
export const userKey = 'user';
export const themeKey = 'theme';
export const basename = import.meta.env.VITE_BASE_URL || '/';
export const empaticaLegal = 'https://empatica.com/legal';
export const careManual = 'assets/Care Portal User Manual-en-UM-89-Rev 6.0.pdf';
